import React from 'react';
export const ModalEmail = ({ isOpen, onClose, children }) => {
    const modalClass = isOpen ? 'fixed inset-0 flex items-center justify-center z-[100]' : 'hidden';
  
    return (
      <div className={`${modalClass} z-[100]`}>
        <div
          className="absolute inset-0 bg-black opacity-30"
          onClick={onClose}
        ></div>
        <div className="bg-white p-4 rounded-md shadow-md z-[100]">
          <div className="mb-4">{children}</div>
          <button
            className="px-4 py-2 button-blue text-white rounded-md"
            onClick={onClose}
          >
            Cerrar
          </button>
        </div>
      </div>
    );
  };