import React from "react";

export const Pricing = (props) => {
  return (
    <section
      id="precios"
      class="min-h-screen bg-gray-50 flex flex-col items-center justify-center container min-w-full sm:pb-10"
    >
      <div class="flex flex-col justify-center items-center py-8">
        <div class="flex flex-col justify-center items-center">
          <h2 class="text-3xl font-black text-gray-800">Nuestras tarifas</h2>
          <hr class="w-1/4 h-1 bg-dblue rounded-full" />
          <br/>
          <div>
            <p class="text-gray-600 text-center">
            Conoce el precio de nuestros servicios, no pagaras extra por tratamientos o valoraciones que necesitemos incluir.
            <br/>
            <span class="text-b text-lg">¡Los bonos se pueden compartir!</span>
            <br/>
            </p>
          </div>
        </div>
      </div>
      <div class="flex flex-col sm:flex-col lg:flex-row xl:flex-row md:flex-row justify-center">
        <div class="py-12 sm:py-12 md:py-6 lg:py-6 xl:py-6 px-12 min-w-[18%] w-full md:max-w-min sm:w-full bg-cblue z-30 xl:min-w-[40%] 2xl:min-w-[40%]">
          <h2 class="text-gray-200 font-semibold text-xl text-center">
            1 sesión
          </h2>
          <div class="text-center py-4 px-7">
            <h2 class="text-gray-100 text-4xl font-black"><span class="text-y">45€ </span></h2>
            <p class="text-gray-300 mt-2"></p>
          </div>
          <div class="h-px bg-gray-200"></div>
          <div class="text-center mt-3">
            <p class="text-sm text-gray-300">
            Nuestra sesión base, con todos nuestros servicios incluidos. <br/>Perfecto para probar la experiencia con nuestros profesionales o un servicio puntual.
            </p>
          </div>
          <button class="w-full mt-6 mb-3 py-2 text-white font-semibold bg-gray-800 hover:shadow-xl duration-200 hover:bg-gray-900">
            <a href="#contacto">Contacta ahora</a>
          </button>
        </div>
        <div class="py-12 sm:py-12 md:py-6 lg:py-6 xl:py-6 px-12 min-w-[20%] w-full xl:min-w-[44%] 2xl:min-w-[44%] md:max-w-min sm:w-full bg-dblue transform scale-1 sm:scale-1 md:scale-105 lg:scale-105 xl:scale-105 z-40 shadow-none sm:shadow-none md:shadow-xl lg:shadow-xl xl:shadow-xl">
          <h2 class="text-y font-semibold text-xl text-center">
          Bono 5 sesiones
          </h2>
          <div class="text-center py-4 px-7">
            <h2 class="text-white text-4xl font-black"><span class="text-y">200€ </span></h2>
            <p class="text-gray-100 mt-2"></p>
          </div>
          <div class="h-px bg-gray-200"></div>
          <div class="text-center mt-3">
            <p class="text-sm text-gray-300">
            Nuestro bono por excelencia, con todos nuestros servicios incluidos.<br/>Perfecto para aquellos que los comparten entre muchas personas.
            </p>
          </div>
          
          <button class="w-full mt-6 mb-3 py-2 text-white font-semibold hover:shadow-xl duration-200 bg-[#f1c40f] hover:bg-[#f1c40fab]">
          <a href="#contacto">Contacta ahora</a>
          </button>
        </div>
        <div class="py-12 sm:py-12 md:py-6 lg:py-6 xl:py-6 px-12 min-w-[18%] xl:min-w-[40%] 2xl:min-w-[40%] w-full md:max-w-min sm:w-full bg-cblue z-30">
          <h2 class="text-gray-200 font-semibold text-xl text-center">
          Bono 10 sesiones
          </h2>
          <div class="text-center py-4 px-7">
            <h2 class="text-gray-100 text-4xl font-black"><span class="text-y">370€ </span></h2>
            <p class="text-gray-300 mt-2"></p>
          </div>
          <div class="h-px bg-gray-200"></div>
          <div class="text-center mt-3">
            <p class="text-sm text-gray-300">
            Nuestro bono para rehabilitaciones largas, con todos nuestros servicios incluidos.<br/>Perfecto para aquellos que los comparten entre muchas personas.
            </p>
          </div>
          <button class="w-full mt-6 mb-3 py-2 text-white font-semibold bg-gray-800 hover:shadow-xl duration-200 hover:bg-gray-900">
          <a href="#contacto">Contacta ahora</a>
          </button>
        </div>
      </div>
      <div>
            <p class="text-gray-600 text-center text-xs pb-8">
            <br/><br/>
            *Los bonos tienen fecha de caducidad de 2 años desde la ultima sesión utilizada, si los usas no caducan.
            </p>
          </div>
    </section>
  );
};
