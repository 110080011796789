import React from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import SmoothScroll from "smooth-scroll";
const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const scrollToElement = (elementId) => {
  const target = document.querySelector(elementId);
  if (target) {
    const offset = target.getBoundingClientRect().top + window.scrollY;
    scroll.animateScroll(offset);
  }
};

export const Navbar = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (elementId) => (event) => {
    event.preventDefault();
    if (location.pathname === "/terminos") {
      navigate("/");
      setTimeout(() => {
        scrollToElement(`#${elementId}`);
      }, 100);
    } else {
      scrollToElement(`#${elementId}`);
    }
  };
  return (
    <nav class="lg:px-16 px-6 bg-white shadow-md flex flex-wrap items-center lg:py-0 py-2 text-gray-900 sticky top-0 z-50">
    <div class="flex-1 flex justify-between items-center">
      <Link to="/" class="flex text-lg font-semibold">
        <img
          src={props.logoUrl}
          width="65"
          height="65"
          class="p-0.5"
          alt="Fisioterapia Aesir"
        />
        <div class="mt-3 text-gray-900 text-lg sm:text-2xl trajan">Fisioterapia Aesir</div>
      </Link>
    </div>
    <label for="menu-toggle" class="cursor-pointer lg:hidden block">
      <svg
        class="fill-current text-gray-900"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
      >
        <title>menu</title>
        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
      </svg>
    </label>
    <input class="hidden" type="checkbox" id="menu-toggle" />
    <div class="hidden lg:flex lg:items-center lg:w-auto w-full" id="menu">
      <nav>
        <ul class="text-xl text-center items-center gap-x-5 pt-4 md:gap-x-4 lg:text-lg lg:flex lg:pt-0">
          <li class="py-2 lg:py-0 ">
            <a
              class="text-gray-900 hover:underline hover:decoration-4 hover:decoration-indigo-900"
              href="#nosotros"
              onClick={handleClick("nosotros")}
            >
              Quienes Somos
            </a>
          </li>
          <li class="py-2 lg:py-0 ">
            <a
              class="text-gray-900 hover:underline hover:decoration-4 hover:decoration-indigo-900"
              href="#tratamientos"
              onClick={handleClick("tratamientos")}
            >
              Tratamientos
            </a>
          </li>
          <li class="py-2 lg:py-0 ">
            <a
              class="text-gray-900 hover:underline hover:decoration-4 hover:decoration-indigo-900"
              href="#precios"
              onClick={handleClick("precios")}
            >
              Precios
            </a>
          </li>
          <li class="py-2 lg:py-0 ">
            <a
              class="text-gray-900 hover:underline hover:decoration-4 hover:decoration-indigo-900"
              href="#contacto"
              onClick={handleClick("contacto")}
            >
              Contacto
            </a>
          </li>
          <li class="py-2 lg:py-0 ">
          <a href="#contacto" role="button" onClick={handleClick("contacto")} class="inline-block rounded-full bg-yellow-700 text-white shadow-[0_4px_9px_-4px_rgba(51,45,45,0.7)] hover:bg-yellow-800 hover:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] focus:bg-yellow-900 focus:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] active:bg-yellow-800 active:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] px-6 pb-2 pt-2.5  font-medium uppercase leading-normal transition duration-150 ease-in-out focus:outline-none focus:ring-0">Pide Cita</a>
          </li>
        </ul>
      </nav>
    </div>
  </nav>
  );
};