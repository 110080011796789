import React from "react";
import { Gallery } from "./Gallery";
export const About = (props) => {
  return (
    <section
      id="nosotros"
      class="min-h-screen bg-gray-50 flex flex-col items-center justify-center container min-w-full pt-8"
    >
      <div class="container m-auto px-6 text-gray-600 md:px-12 xl:px-6">
        <div class="space-y-6 md:space-y-0 md:flex md:gap-6 xl:items-center lg:gap-12 md:flex-col sm:flex-col lg:flex-row">
          <div class="w-fit lg:w-5/12 items-center justify-center">
            <img
              src={props.images[3]}
              alt="fisioterapia aravaca"
              loading="lazy"
              class="rounded"
            />
          </div>
          <div class="md:7/12 lg:w-6/12">
          <div class="flex flex-col justify-center items-center">
            <h2 class="text-3xl font-black text-gray-800 text-center">Nuestra clínica de fisioterapia en Aravaca</h2>
            <hr class="w-2/4 h-1 bg-dblue rounded-full" />
          </div>
            <p class="mt-6 text-gray-600">
              Fisioterapia Aesir está situada en la calle Veleta 13, entre la
              estación de Aravaca y el casco histórico. Abrimos puertas iniciando
              un nuevo proyecto ambicioso, con unas instalaciones completamente
              nuevas, con amplias salas totalmente equipadas y maquinaria de
              última generación.
            </p>
            <p class="mt-4 text-gray-600">
              {" "}
              Actualmente ofrecemos además tratamientos personalizados en el
              campo de la Fisioterapia (Deporte, Traumatología, Reumatología,
              Neurología, Respiratoria, etc) como también específicos e
              innovadores en Fisioterapia como la Punción Seca, Diatermia, la
              Electroestimulación Percutánea (EPTE) y Neuromodulación.
            </p>
          </div>
        </div>
        <div className="w-fit lg:w-1/2 mx-auto mb-8 md:mb-0">
          <Gallery images={props.images}/>
        </div>
      </div>
    </section>
  );
};
