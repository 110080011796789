import React from "react";

export const Treatments = (props) => {
  return (
    <section
      id="tratamientos"
      class="p-auto pt-20 pb-8 md:pt-16 md:pb-0 bg-gray-200"
    >
      <div class="container xl:max-w-6xl mx-auto px-4">
        <div class="flex flex-col justify-center items-center">
          <h2 class="text-3xl font-black text-gray-800">Nuestros tratamientos</h2>
          <hr class="w-1/4 h-1 bg-dblue rounded-full" />
          <p class="text-gray-600 leading-relaxed text-xl mx-auto pb-2 mt-4">
          Escríbenos si tienes dudas sobre tu patología y te diremos cómo te podemos ayudar.
          </p>
        </div>
        <div class="flex flex-wrap flex-row -mx-4 text-center">
        <div
            class="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay=".1s"
            style={{
              visibility: "visible",
              animationDuration: "1s",
              animationDelay: "0.1s",
              animationName: "fadeInUp",
            }}
          >
            <div class="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 transform transition duration-300 ease-in-out hover:-translate-y-2">
              <div class="inline-block text-gray-900 mb-4"></div>
              <h3 class="text-lg leading-normal mb-2 font-semibold text-gray-900">
              Fisioterapia traumatológica
              </h3>
              <p class="text-gray-600">
              Especializados en la patología musculoesquelética: dolores musculares, esguinces, roturas…
              </p>
            </div>
          </div>
          <div
            class="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay=".1s"
            style={{
              visibility: "visible",
              animationDuration: "1s",
              animationDelay: "0.1s",
              animationName: "fadeInUp",
            }}
          >
            <div class="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 transform transition duration-300 ease-in-out hover:-translate-y-2">
              <div class="inline-block text-gray-900 mb-4"></div>
              <h3 class="text-lg leading-normal mb-2 font-semibold text-gray-900">
              Fisioterapia deportiva
              </h3>
              <p class="text-gray-600">
              Especializados en la patología deportiva, entendiendo la biomecánica de los deportes específicos.
              </p>
            </div>
          </div>
          <div
            class="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay=".3s"
            style={{
              visibility: "visible",
              animationDuration: "1s",
              animationDelay: "0.3s",
              animationName: "fadeInUp",
            }}
          >
            <div class="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 transform transition duration-300 ease-in-out hover:-translate-y-2">
              <div class="inline-block text-gray-900 mb-4"></div>
              <h3 class="text-lg leading-normal mb-2 font-semibold text-gray-900">
              Fisioterapia neurológica
              </h3>
              <p class="text-gray-600">
              Especializados en la mejora de la calidad de vida e independencia de la persona con afectaciones neurológicas (ictus, Parkinson, EM…)
              </p>
            </div>
          </div>
          <div
            class="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp"
            data-wow-duration="1s"
            style={{
              visibility: "visible",
              animationDuration: "1s",
              animationName: "fadeInUp",
            }}
          >
            <div class="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 transform transition duration-300 ease-in-out hover:-translate-y-2">
              <div class="inline-block text-gray-900 mb-4"></div>
              <h3 class="text-lg leading-normal mb-2 font-semibold text-gray-900">
              Fisioterapia para la ATM
              </h3>
              <p class="text-gray-600">
              Para los trastornos de la articulación temporo-mandibular: Bruxismo, dolores y bloqueos en la mandíbula…
              </p>
            </div>
          </div>
          <div
            class="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay=".1s"
            style={{
              visibility: "visible",
              animationDuration: "1s",
              animationDelay: "0.1s",
              animationName: "fadeInUp",
            }}
          >
            <div class="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 transform transition duration-300 ease-in-out hover:-translate-y-2">
              <div class="inline-block text-gray-900 mb-4"></div>
              <h3 class="text-lg leading-normal mb-2 font-semibold text-gray-900">
              Tecnicas ecoguiadas/invasivas
              </h3>
              <p class="text-gray-600">
              Especializados en técnicas de fisioterapia invasiva como punción seca, EPI/EPTE, Neuromodulacion…
              </p>
            </div>
          </div>
          <div
            class="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay=".3s"
            style={{
              visibility: "visible",
              animationDuration: "1s",
              animationDelay: "0.3s",
              animationName: "fadeInUp",
            }}
          >
            <div class="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 transform transition duration-300 ease-in-out hover:-translate-y-2">
              <div class="inline-block text-gray-900 mb-4"></div>
              <h3 class="text-lg leading-normal mb-2 font-semibold text-gray-900">
              Trastornos del dolor
              </h3>
              <p class="text-gray-600">
              Nos especializamos en “dolores difíciles”: Migrañas, Dolores de Cabeza, Parestesias, Dolores Irradiados nerviosos.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
