import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { ModalEmail } from "./ModalEmail";
export const Contact = (props) => {
  const form = useRef();
  const [successfulSubmission, setSuccessfulSubmission] = useState(false);
  const [loading, setLoading] = useState(false);
  const closeModal = () => {
    setSuccessfulSubmission(false);
  };
  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    emailjs
      .sendForm(
        "service_ocnj1rx",
        "template_myc2yje",
        form.current,
        "BdbE9kPVBj3xzpR2Z"
      )
      .then(
        (result) => {
          console.log(result.text);
          setSuccessfulSubmission(true);
          setLoading(false);
          form.current.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <section id="contacto" class="text-gray-700 body-font relative">
      <div class="absolute inset-0 bg-gray-200">
        <div class="flex flex-row justify-center items-center py-8">
          <div class="flex flex-col justify-center items-center">
            <h2 class="text-3xl font-black text-gray-800">
              Contacta con nosotros
            </h2>
            <hr class="w-5/6 h-1 bg-dblue rounded-full" />
          </div>
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1517.9540477640483!2d-3.7814265891642296!3d40.455171054699576!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd4187f3def34615%3A0x913616fb01953328!2sFisioterapia%20Aesir!5e0!3m2!1ses!2ses!4v1701555072147!5m2!1ses!2ses"
          width="100%"
          height="100%"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
          title="map"
          scrolling="no"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <div class="container px-5 py-24 mx-auto flex">
        <div class="lg:w-1/3 md:w-1/2 bg-white rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md">
          <h2 class="text-gray-900 text-lg mb-1 font-medium title-font">
            Pide cita
          </h2>
          <p class="leading-relaxed mb-5 text-gray-600">
            Nuestra información de contacto:
          </p>
          <p class="leading-relaxed text-gray-600">
            <span>
              <i class="fa-solid fa-phone"></i>
            </span>{" "}
            <a
              class="underline"
              href="https://api.whatsapp.com/send?phone=621352582"
            >
              +34 621 352 582
            </a>
          </p>
          <p class="leading-relaxed text-gray-600">
            <span>
              <i class="fa-solid fa-envelope"></i>
            </span>{" "}
            <a class="underline" href="mailto:contacto@aesirfisioterapia.com">
              contacto@aesirfisioterapia.com
            </a>
          </p>
          <p class="leading-relaxed mb-5 text-gray-600">
            <span>
              <i class="fa-solid fa-compass"></i>
            </span>{" "}
            <a
              class="underline"
              href="https://maps.app.goo.gl/kngP9xGtM2Va9XHt7"
            >
              C/ Veleta, nº 13, 28023, Madrid
            </a>
          </p>
          <div class="relative mb-4">
            <form ref={form} onSubmit={sendEmail}>
              <label for="name" class="leading-7 text-sm text-gray-600">
                Nombre
              </label>
              <input
                type="name"
                id="name"
                name="name"
                required
                class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
              <label for="surname" class="leading-7 text-sm text-gray-600">
                Apellidos
              </label>
              <input
                type="surname"
                id="surname"
                name="surname"
                required
                class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
              <label for="email" class="leading-7 text-sm text-gray-600">
                Tu Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                required
                class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
              <label for="phone" class="leading-7 text-sm text-gray-600">
                Tu teléfono
              </label>
              <input
                type="text"
                id="phone"
                name="phone"
                required
                class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
              <div class="relative mb-4">
                <label for="message" class="leading-7 text-sm text-gray-600">
                  Mensaje
                </label>
                <textarea
                  id="message"
                  name="message"
                  required
                  class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                ></textarea>
              </div>
              <label
                for="consentimiento"
                class="leading-7 text-sm text-gray-600"
              >
                <input type="checkbox" name="consentimiento" required></input>{" "}
                Acepto los{" "}
                <a href="/terminos" class="underline font-bold" target="_blank">
                  términos y condiciones
                </a>
                .
              </label>
              <br></br>
              <button
                type="submit"
                class="text-white button-blue border-0 py-2 px-6 focus:outline-none rounded text-lg"
              >
                Enviar
              </button>
              {loading ? (
                <i className="fas fa-circle-notch fa-spin fa-2x"></i>
              ) : null}
            </form>
            <ModalEmail isOpen={successfulSubmission} onClose={closeModal}>
              <h3 class="text-gray-900 text-lg mb-1 font-medium title-font">
                Email enviado
              </h3>
              <p class="leading-relaxed mb-5 text-gray-600">
                Recibirá una respuesta en el menor tiempo posible.
              </p>
            </ModalEmail>
          </div>
        </div>
      </div>
    </section>
  );
};
