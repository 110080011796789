import React from "react";

export const Footer = (props) => {
  return (
    <footer className="bg-gray-200 text-center text-neutral-600 dark:bg-neutral-600 dark:text-neutral-200 lg:text-left pt-20 sm:pt-20 md:pt-28 lg:pt-20 xl:pt-20 2xl:pt-36">
      <div className="mx-6 py-10 text-center md:text-left">
        <div className="grid-1 grid gap-8 md:grid-cols-2 lg:grid-cols-4">
          {/* <!-- TW Elements section --> */}
          <div className="">
            <h6 className="mb-4 flex items-center justify-center font-semibold uppercase md:justify-start">
              <img
                src={props.logoUrl}
                width="40"
                height="40"
                class="p-0.5"
                alt="Fisioterapia Aesir"
              />
              Fisioterapia Aesir
            </h6>
            <p>
              Contacta con nuestros profesionales o ven a conocernos
              directamente a nuestra clínica.
            </p>
          </div>
          {/* <!-- Useful links section --> */}
          <div className="">
            <h6 className="mb-4 flex justify-center font-semibold uppercase md:justify-start">
              Links importantes
            </h6>
            <p className="mb-4">
              <a
                className="text-neutral-600 dark:text-neutral-200"
                href="#nosotros"
              >
                Quienes somos
              </a>
            </p>
            <p className="mb-4">
              <a
                className="text-neutral-600 dark:text-neutral-200"
                href="#tratamientos"
              >
                Tratamientos
              </a>
            </p>
            <p className="mb-4">
              <a
                className="text-neutral-600 dark:text-neutral-200"
                href="#precios"
              >
                Precios
              </a>
            </p>
            <p>
              <a
                className="text-neutral-600 dark:text-neutral-200"
                href="#contacto"
              >
                Contacto
              </a>
            </p>
          </div>
          {/* <!-- Contact section --> */}
          <div>
            <h6 className="mb-4 flex justify-center font-semibold uppercase md:justify-start">
              Contacto
            </h6>
            <p className="mb-4 flex items-center justify-center md:justify-start">
              <span>
                <i class="fa-solid fa-phone">&ensp;</i>
              </span>
              {""}
              <a
                class="underline"
                href="https://api.whatsapp.com/send?phone=621352582"
              >
                +34 621 352 582
              </a>
            </p>
            <p className="mb-4 flex items-center justify-center md:justify-start">
              <span>
                <i class="fa-solid fa-envelope">&ensp;</i>
              </span>{" "}
              <a class="underline" href="mailto:contacto@aesirfisioterapia.com">
                contacto@aesirfisioterapia.com
              </a>
            </p>
            <p className="mb-4 flex items-center justify-center md:justify-start">
              <span>
                <i class="fa-solid fa-compass">&ensp;</i>
              </span>{" "}
              <a
                class="underline"
                href="https://maps.app.goo.gl/kngP9xGtM2Va9XHt7"
              >
                C/ Veleta, nº 13, 28023, Madrid
              </a>
            </p>
          </div>
        </div>
      </div>
      <div class="mx-6">
            <p class="text-[10px] text-justify text-gray-600">
              Para acceder a algunos de los servicios que FISIOTERAPIA
              AESIR ofrece a través del website deberá proporcionar algunos
              datos de carácter personal. En cumplimiento de lo establecido en
              el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo,
              de 27 de abril de 2016, relativo a la protección de las personas
              físicas en lo que respecta al tratamiento de datos personales y a
              la libre circulación de estos datos, le informamos que, mediante
              la cumplimentación de los presentes formularios, sus datos
              personales quedarán incorporados y serán tratados en los ficheros
              de FISIOTERAPIA AESIR con el fin de poderle prestar y ofrecer
              nuestros servicios, así como para informarle de las mejoras del
              sitio Web. Asimismo, le informamos de la posibilidad de que ejerza
              los derechos de acceso, rectificación, cancelación y oposición de
              sus datos de carácter personal, de manera gratuita, mediante email
              a <a class="underline" href="mailto:contacto@aesirfisioterapia.com">contacto@aesirfisioterapia.com</a> o en la dirección
              C/ Veleta, nº 13, Local 1, 28023, Madrid.
            </p>
      </div>
      <br/>
      {/* <!--Copyright section--> */}
      <div class="text-center justify-center items-center text-xs">
        <p>
          &copy; 2023 Clinica Fisioterapia Aesir. Design by{" "}
          <a
            href="https://www.linkedin.com/in/ruben-fernandez-aliman/"
            rel="noreferrer"
            target="_blank"
            class="text-b font-bold"
          >
            Rubén
          </a>
        </p>
      </div>
    </footer>
  );
};
