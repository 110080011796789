import React from "react";

export const Header = (props) => {
  return (
    <section
      id="top"
      class="h-screen w-full bg-cover bg-center py-18 intro bg-gray-50 2xl:py-40"
    >
      <div class="container mx-auto text-center text-white py-10">
        <div class="bg-black bg-opacity-30 rounded-lg py-6 px-3 w-fit mx-auto">
          <h1 class="text-5xl font-medium ">
            CLÍNICA FISIOTERAPIA
            <br />
            <span class="text-y">AESIR</span>
            <br />
            EN ARAVACA
          </h1>
          <p class="text-xl my-12">VISITA NUESTRAS REDES SOCIALES</p>
          <div class="flex justify-center space-x-6 ">
            <a
              href="https://www.instagram.com/aesirfisioterapia"
              role="button"
              rel="noreferrer"
              target="_blank"
              class="inline-block rounded-full bg-[#3b5078] text-neutral-50 shadow-[0_4px_9px_-4px_rgba(51,45,45,0.7)] hover:bg-[#4e7ab3] hover:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] focus:bg-[#1d314e] focus:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] active:bg-[#1d314e] active:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] py-2 px-3.5 font-medium uppercase leading-normal transition duration-150 ease-in-out focus:outline-none focus:ring-0"
            >
              <i className="fa-brands fa-instagram text-xl"></i>
            </a>
            <a
              href="https://www.facebook.com/aesirfisioterapia"
              role="button"
              rel="noreferrer"
              target="_blank"
              class="inline-block rounded-full bg-[#3b5078] text-neutral-50 shadow-[0_4px_9px_-4px_rgba(51,45,45,0.7)] hover:bg-[#4e7ab3] hover:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] focus:bg-[#1d314e] focus:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] active:bg-[#1d314e] active:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] py-2 px-3.5 font-medium uppercase leading-normal transition duration-150 ease-in-out focus:outline-none focus:ring-0"
            >
              <i className="fa-brands fa-facebook text-xl"></i>
            </a>
            <a
              href="https://www.tiktok.com/@aesirfisioterapia"
              role="button"
              rel="noreferrer"
              target="_blank"
              class="inline-block rounded-full bg-[#3b5078] text-neutral-50 shadow-[0_4px_9px_-4px_rgba(51,45,45,0.7)] hover:bg-[#4e7ab3] hover:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] focus:bg-[#1d314e] focus:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] active:bg-[#1d314e] active:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] py-2 px-3.5 font-medium uppercase leading-normal transition duration-150 ease-in-out focus:outline-none focus:ring-0"
            >
              <i className="fa-brands fa-tiktok text-xl"></i>
            </a>
          </div>
          <br />
          <br />
          <br />
          <a
            href="#contacto"
            role="button"
            class="inline-block rounded-full bg-yellow-700 text-white shadow-[0_4px_9px_-4px_rgba(51,45,45,0.7)] hover:bg-yellow-800 hover:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] focus:bg-yellow-900 focus:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] active:bg-yellow-800 active:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] px-6 pb-2 pt-2.5  font-medium uppercase leading-normal transition duration-150 ease-in-out focus:outline-none focus:ring-0"
          >
            Pide Cita
          </a>
        </div>
      </div>
    </section>
  );
};
