import React from "react";
import { Navbar } from "./Navbar";
import { Pricing } from "./Pricing";
import { Contact } from "./Contact";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { About } from "./About";
import { Treatments } from "./Treatments";
import logo from ".././logo.webp";
import ".././App.css";
import eco from ".././images/eco.webp";
import masaje from ".././images/masaje.webp";
import sala1 from ".././images/sala1.webp";
import recepcion from ".././images/recepcion.webp";
import entrada1 from ".././images/entrada1.webp";
import entrada2 from ".././images/entrada2.webp";

export const Home = (props) => {
  return (
    <div>
      <Navbar logoUrl={logo} />
      <Header />
      <About images={[eco, masaje, sala1, recepcion, entrada1, entrada2]} />
      <Treatments />
      <Pricing />
      <Contact />
      <hr />
      <Footer logoUrl={logo} />
    </div>
  );
};
