import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Terminos } from "./components/Terminos";
import { Home } from "./components/Home";
import { NotFound } from './components/NotFound';
import "./App.css";

function App() {
  return (
    <Router basename={"/"}>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/terminos" element={<Terminos />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}


export default App;
