import { Carousel } from "@material-tailwind/react";

export function Gallery(props) {
  return (
    <Carousel className="py-8">
      <img
        src={props.images[1]}
        alt="masaje"
        className="w-full h-full object-cover rounded"
      />
      <img
        src={props.images[2]}
        alt="sala tratamientos"
        className="w-full h-full object-cover rounded"
      />
      <img
        src={props.images[5]}
        alt="entrada"
        className="w-full h-full object-cover rounded"
      />
    </Carousel>
  );
}