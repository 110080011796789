import React from "react";
import { Navbar } from "./Navbar";
import logo from ".././logo.png";
export const Terminos = (props) => {
  return (
    <div>
      <Navbar logoUrl={logo} />
      <div className="container mx-auto my-8 p-8 bg-gray-100">
        <h1 className="text-3xl font-bold mb-4">Política de Privacidad</h1>

        <p>
          FISIOTERAPIA AESIR, en aplicación de la normativa vigente en materia
          de protección de datos de carácter personal, informa que los datos
          personales que se recogen a través de los formularios del Sitio web:
          <a class="underline" href="/">www.aesirfisioterapia.com</a>, se incluyen en los ficheros automatizados
          específicos de usuarios de los servicios de FISIOTERAPIA AESIR.
        </p>

        <p>
          La recogida y tratamiento automatizado de los datos de carácter
          personal tiene como finalidad el mantenimiento de la relación
          comercial y el desempeño de tareas de información, formación,
          asesoramiento y otras actividades propias de FISIOTERAPIA AESIR.
        </p>

        <p>
          Estos datos únicamente serán cedidos a aquellas entidades que sean
          necesarias con el único objetivo de dar cumplimiento a la finalidad
          anteriormente expuesta. FISIOTERAPIA AESIR adopta las medidas
          necesarias para garantizar la seguridad, integridad y confidencialidad
          de los datos conforme a lo dispuesto en el Reglamento (UE) 2016/679
          del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo
          a la protección de las personas físicas en lo que respecta al
          tratamiento de datos personales y a la libre circulación de los
          mismos.
        </p>

        <h2 className="text-2xl font-bold mt-4">
          Finalidad del tratamiento de los datos personales:
        </h2>

        <p>
          ¿Con qué finalidad trataremos tus datos personales? <br/> En
          FISIOTERAPIA AESIR, trataremos tus datos personales recabados a través del
          Sitio Web: <a class="underline" href="/">www.aesirfisioterapia.com</a>, con las siguientes finalidades:
        </p>

        <ul className="list-disc pl-6">
          <li>
            En caso de contratación de los bienes y servicios ofertados a través
            de FISIOTERAPIA AESIR, para mantener la relación contractual, así
            como la gestión, administración, información, prestación y mejora
            del servicio.
          </li>
          <li>
            Envío de información solicitada a través de los formularios
            dispuestos en FISIOTERAPIA AESIR.
          </li>
          <li>
            Remitir boletines (newsletters), así como comunicaciones comerciales
            de promociones y/o publicidad de FISIOTERAPIA AESIR y del sector.
          </li>
        </ul>

        <p>
          Te recordamos que puedes oponerte al envío de comunicaciones
          comerciales por cualquier vía y en cualquier momento, remitiendo un
          correo electrónico a la dirección indicada anteriormente.
        </p>

        <p>
          Los campos de dichos registros son de cumplimentación obligatoria,
          siendo imposible realizar las finalidades expresadas si no se aportan
          esos datos.
        </p>
        <h2 className="text-2xl font-bold mt-4">
          ¿Por cuánto tiempo se conservan los datos personales recabados?
        </h2>

        <p>
          Los datos personales proporcionados se conservarán mientras se
          mantenga la relación comercial o no solicites su supresión y durante
          el plazo por el cual pudieran derivarse responsabilidades legales por
          los servicios prestados.
        </p>
        <h2 className="text-2xl font-bold mt-4">
          Cambios en la política de privacidad:
        </h2>

        <p>
          FISIOTERAPIA AESIR se reserva el derecho de modificar la presente
          política para adaptarla a novedades legislativas o jurisprudenciales,
          así como a prácticas de la industria. En dichos supuestos,
          FISIOTERAPIA AESIR anunciará en esta página los cambios introducidos con
          razonable antelación a su puesta en práctica.
        </p>

        <h2 className="text-2xl font-bold mt-4">Contacto:</h2>

        <p>
          Para cualquier cuestión respecto a los términos y condiciones
          descritos en esta política de privacidad, puedes ponerte en contacto
          con nosotros a través de la siguiente información:
        </p>

        <p>Email: <a class="underline" href="mailto:contacto@aesirfisioterapia.com">contacto@aesirfisioterapia.com</a></p>

        <p>Dirección: C/ Veleta, nº 13, Local 1, 28023, Madrid.</p>
      </div>
    </div>
  );
};
